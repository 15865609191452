import React from "react";
import styles from "./Logo.module.scss";
import THEME from "../../../state/theme";
//import logo_bleu from '../../../assets/images/logo_bleu.svg';
import logo_bleu from '../../../assets/images/structurea_bold.svg';

export default ({ dark, logo }) => (
  <figure
    className={[styles.logo, dark ? styles.dark : styles.light].join(" ")}
    style={{ borderColor: THEME.color }}
  >
    <img src={logo} alt="logo_structurea" />
  </figure>
);
