import React, { useState, useEffect, Fragment } from "react";
import { animateScroll as scroll } from 'react-scroll';
import styles from "./Header.module.scss";
import Layout from "../Layout/Layout";
import { Button, Link, Logo, LinkS } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import logo_bleu from '../../../assets/images/structurea_bold.svg';

export default ({ data = [] }) => {
  const [sticky, setSticky] = useState(false);
  const actions = useCustomState()[1];

  const handleResize = () => {
    setSticky(window.pageYOffset > 200 ? true : false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  const backToTop = () => {
    scroll.scrollToTop();
  };

  const menu = data.map((item, index) => {
    if (!item.children) {
      return (
        <li key={index}>
          <LinkS url={item.url} hoverStyle={{ color: THEME.color }}>
            {item.name}
          </LinkS>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <span>
            <LinkS url={item.url} hoverStyle={{ color: THEME.color }}>
              {item.name}
            </LinkS>
          </span>
          <ul>
            {item.children.map((subitem, subindex) => (
              <li key={subindex}>
                <a href={subitem.url}>{subitem.name}</a>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  const header = (
    <Layout>
      <div className={styles.header} >
        <Link url="/" click={backToTop}>
          <Logo dark logo={logo_bleu} />
        </Link>

        <ul className={styles.menu}>{menu}</ul>

        <div className={styles.btn_desktop}>
          <Button
            after=""
            type="outline-color-tb"
            hoverType="solid-color-tw"
            edge
            click={() => actions.toogleModal()}
          >
            ENVOYER MESSAGE
          </Button>
        </div>

        <div
          className={styles.btn_mobile}
          onClick={() => actions.toogleSidebar()}
        >
          <Button
            after="&#xf0c9;"
            type="solid-white-tb"
            hoverType="solid-gray-tb"

          />
        </div>
      </div>
    </Layout>
  );

  return (
    <Fragment>
      <div className={styles.wrapper} >{header}</div>
      <div
        className={[
          styles.wrapper,
          sticky ? styles.sticky : styles.hidden,
        ].join(" ")}
      >
        {header}
      </div>
    </Fragment>
  );
};
